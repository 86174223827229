.ss-result {
    background-color: $brand-lightblue;
    @include shadow-z-1();
    @include transition(all 0.33s ease);
    margin-bottom: 20px;
    padding: 0 ($grid-gutter-width / 2);

    > .row {
        padding: ($grid-gutter-width / 2) 0;
    }

    &:hover {
        @include shadow-z-2();
    }

    mark {
        padding-left: 0;
        padding-right: 0;
    }
    //    margin-bottom: 2em;
    //    border-bottom: 1px solid $brand-lightblue;
}
.ss-result__title {
    @extend .h2;
    margin-top: ($line-height-computed / 2);
}
.ss-result__badge {
    font-size: 0.7em;
    padding: 0.2em 0.5em;
    border-radius: 4px;
    margin-left: 0.75em;
    background: #eee;
    display: inline-block;
}
.ss-result__text {
    margin-bottom: 0.5em;
}
.ss-result__url {
    @extend .btn-like;
}

.navbar-suche {
    position: relative;

    .search-form {
        background-color: rgba($brand-primary, 0.95);
        position: absolute;
        z-index: $zindex-popover;
        right: 100%;
        height: 100%;
        border: 0;
        padding: 0;
        padding-top: 13px;
        width: 0;
        overflow: hidden;
        transition: width 0.33s ease-in-out;

        @media (max-width: $screen-xs-max) {
          padding-top: 5px;
        }

        @media (max-width: $screen-xs) {
            right: auto;
            padding-top: 2px;
            //            margin: 0 ($grid-gutter-width / 2);
            position: relative;
            float: right;
        }

        .form-control {
            background-color: $brand-lightblue;
            box-shadow: inset 0 1px 3px rgba($brand-primary, 0.75),
                0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
        }

        .form-group {
          margin-bottom: 0;
        }
    }

    &.expanded {
        .search-form {
            width: 240px;
            padding-left: ($grid-gutter-width / 4);
            padding-right: ($grid-gutter-width / 4);
        }
    }
}
