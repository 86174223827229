/* img, iframe {
  aspect-ratio: attr(width) / attr(height); // justt a draft spec: https://drafts.csswg.org/css-sizing-4/#ratios
}
 */
.sanofi-bar {
//    display: none;
    height: 14px;
    margin: 3px;
    background-image: url('../img/sanofi.CACHEBUSTER.svg');
    background-size: contain;
    background-size: 66px 14px;
    background-position: center right;
    background-repeat: no-repeat;
    cursor: pointer;
}

.mood {
    width: 100%;
    height: 33vh;
    overflow: hidden;

    background-size: cover;
    background-position: center right;
}
.mood-left {
    background-position: center left;
}

.h1-like {
    margin-top: $line-height-computed;
    margin-bottom: ($line-height-computed / 2);
}


@media (min-width: 300px) {
  .modal-lg { width: $modal-lg; }
}

.tab-content {
    background-color: #fff;
    padding: ($grid-gutter-width / 2);
}

.spaltenlos > .content,
.tab-pane > .content,
.panel-group,
.container form {
    max-width: $content-width-max;
    margin: 0 auto;
}

.match-height {
    overflow: hidden;
}

.fa-li {
    top: .25em;
}

.progress-bar {
    font-size: 14px;
}

$btdsgn-li-width: 1.33rem;
.btdsgn-ul {
  @extend .text-muted;
  margin-top: 1rem;
  margin-bottom: 1em;
  margin-left: $btdsgn-li-width;
  border-top: 1px solid rgba($text-muted, 0.5);
  padding-top: 0.5rem;
  padding-left: 0;
  list-style-type: none;
  > li {
    position: relative;

    .btdsgn-li {
      position: absolute;
      left: -$btdsgn-li-width;
      width: 1rem;
      top: 0.67rem;
      font-size: 1rem;
    }
  }
}

.thumbnail .caption {
    *:last-child {
        margin-bottom: 0;
    }
}

.media-left {
  padding-right: ($grid-gutter-width / 2);
}
.media-object {
  border-radius: 0.5rem;
}

.attention {
  font-size: $font-size-h3;
  font-weight: 600;
}

.cta {
  text-align: center;
}

#scrolltop-helper {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 99999;
  display: none;
}

body.ffm-tipp {

  #scrolltop-helper {
      bottom: 75px;
      right: 25px;
  }

}

.new-design-badge {
  shape-outside: circle(50%); // url('../img/packshots/new-design-badge.CACHEBUSTER.png');
  // shape-image-threshold: 0;
}
.nr1-badge {
  position: relative;
  z-index: 9;
  margin-bottom: -15%;

  @media (max-width: $screen-sm) {
    max-width: 33%;
    margin-bottom: -20%;
  }
}

.img-inner{
  position: relative;

  .box-blue {
    background-color: rgba(231, 238, 235, 0.71);
    position: absolute;
    bottom: 1em;
    left: 1em;
    right: 1em;
    padding: 1em;

    font-weight: bold;

  }

}

.img-responsive {
  &.ffm-inner {
    min-height: 13em;
  }
}


.hero-move {
  @media (min-width: $screen-md-min) {
    padding-top: 5em;
  }
  @media (max-width: $screen-md-min) {
    padding-top: 1em;
  }
}


.mobile-padd {
  @media (max-width: $screen-sm-min) {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@import "./_forms";
@import "./_wtfforms";
@import "./_search";
@import "./_pflichtpop";

@import "./_responsive-addons";
