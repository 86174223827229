.navsub {
  &.navsub-vivid {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
    .mCSB_horizontal.mCSB_inside > .mCSB_container {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  .nav-pills {
    margin-top: 20px;
    margin-bottom: 10px;

    &.nav-vivid {
      display: inline-block;
      width: auto;
      margin: 0 auto -8px;
      white-space: nowrap;

      > li {
        line-height: 1;
        float: left;

        + .li-vivid {
          padding-left: $navbar-padding-horizontal;
        }

        > a {
          display: block;
          background-color: transparent;
          border: 1px solid transparenth;
          text-align: center;
          // margin-bottom: 5px;
          // text-transform: uppercase;
          opacity: 0;
          @include transition(opacity .67s ease-out);

          > span {
            font-size: 15px;
          }


        }

        &.active {
          > a {
            font-weight: bold;
            color: $brand-primary;
          }
        }
      }
    }
  }

  &.overflowed {
      margin-bottom: -11px;

      .nav-vivid.nav-pills {
        margin-bottom: 10px;
      }
  }
}

.vivid-bar {
  position: relative;
  z-index: ($zindex-navbar-fixed - 1);
  background-color: $brand-lightblue;
//  @include gradient-vertical(white, $brand-lightblue);

  .img-responsive {
    position: relative;
    opacity: 1;
    margin: 0 auto;
    @include transition(all .5s ease);

  }

  > .container {
    > .row {
      position: relative;
      padding-left: 32px;
      padding-right: 32px;

      .scroll-left,
      .scroll-right {
        position: absolute;
        display: block;
        z-index: ($zindex-navbar-fixed + 1);
        top: 50%;
        transform: translateY(-50%);
        margin-top: -2px;
        height: auto;
        background-color: transparent;
        font-family: FontAwesome;
        font-size: 32px;
        line-height: 1;
        color: rgba($brand-primary, .33);
        border: 0 solid $brand-lightblue;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
          color: $brand-primary;
        }
      }
      .scroll-left {
        padding: 0 9px;
        left: 0;
      }
      .scroll-right {
        padding: 0 9px;
        right: 0;
      }
    }
  }
}

.headroom {
    will-change: transform;
    transition: transform 333ms linear;
}
.headroom--not-top {
  &.headroom--pinned {
        position: fixed;
        top: 50px;
        right: 0;
        left: 0;
        z-index: ($zindex-navbar-fixed - 2);
        background-color: $brand-lightblue;
        opacity: .95;
        transform: translateY(0%);

        $shadow: 0 2px 2px rgba(0, 0, 0, .2);
        @include box-shadow($shadow);

        &:hover {
            opacity: 1;
        }

        @media (min-width: 481px) {
            top: 52px;
        }
        @media (min-width: 801px) {
            top: 67px;
        }
    }

    &.headroom--unpinned {
        transform: translateY(-100%);
    }
}
