@import "bootstrap/_navbar";

/*
#navigation-bar {
  visibility: hidden;
}
*/
#collapseProductsMenue {
  position: fixed;
  top: $navbar-height;
  width: 100%;
  z-index: 99;

  @include shadow-z-1();
}
.navbar {
  border: 0 solid transparent;
  margin-bottom: 0;

  .navbar-brand {
    display: block;
    padding: 0 $navbar-padding-horizontal;
    height: 100%;
    overflow: hidden;

    @media (max-width: $grid-float-breakpoint) {
      max-height: 50px;
    }

    > img {
      height: 100%;
      width: auto;
    }
  }
}

@media (min-width: $grid-float-breakpoint) {
  .navbar > .container .navbar-brand {
    margin-left: 0;
  }
}

.navbar-fixed-top {
  border-width: 0 0 0;
  @include box-shadow(0 0 5px 3px rgba(0, 0, 0, 0.2));
}

.navbar-default {
  @media (max-width: $screen-xs) {
    .navbar-collapse {
      margin: 8px 0 0;
    }
  }
  @media (max-width: $grid-float-breakpoint-max) {
    .navbar-collapse {
      position: relative;
      z-index: ($zindex-navbar-fixed * 2);
      background-color: $brand-primary;
    }
  }
  .navbar-nav {
    > li > a {
      font-family: $font-family-condensed;
      text-transform: uppercase;
      color: $brand-lightblue;
    }

    &.navbar-main {
      > li {
        > a {
          font-size: 20px;
          font-weight: 700;
          padding-right: 20px;
          padding-left: 0;

          @media (max-width: $screen-xs-max) {
            padding-left: 15px;
            padding-right: 15px;
          }

          @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            font-size: 16px;
            padding-right: 16px;
          }

          @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            font-size: 18px;
            padding-right: 24px;
          }

          @media (min-width: $screen-lg-min) {
            font-size: 22px;
            padding-right: 35px;
          }

          @media (min-width: $grid-float-breakpoint) {
            padding-top: $navbar-padding-vertical;
            padding-bottom: ($navbar-padding-vertical - 2);
          }
        }

        &.thin {
          > a {
            font-size: 15px;
            font-weight: 400;
            text-transform: none;
          }
        }

        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
          &:last-child {
            > a {
              padding-right: 20px;
            }
          }
        }

        @media (max-width: $screen-xs-max) {
          > a {
            border-bottom: 1px solid lighten($brand-primary, 3%);
          }

          &.active > a {
            &,
            &:hover,
            &:focus {
              > span {
                border-bottom: 4px solid transparent;
              }
            }
          }
        }
      }

      > .active > a {
        &,
        &:hover,
        &:focus {
          > span {
            border-bottom: 4px solid $brand-classic;
          }
        }
      }
    }
  }

  .navbar-misc {
    > li > a {
      //      font-family: $font-family-sans-serif;
      text-transform: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
      color: $brand-lightblue;
      padding: 5px $navbar-padding-horizontal;
      margin: 5px 0;

      display: inline-block;
      border-left: 1px solid rgba(lighten($brand-classic, 50%), 0.25);
    }
  }

  @media (max-width: $screen-sm-max) {
    .navbar-brand {
      float: right;
      // width: 260px;
      // margin-top: 9px;
    }
  }

  @media (max-width: ($screen-xs - 1)) {
    .navbar-brand {
      position: absolute;
      left: 50%;
      transform: translateX(-56%);

      &.ffm {
       left: 0;
        transform: unset;
        -webkit-transform: unset;
      }
      // width: 200px;
      // margin-top: 6px; // 12px;
    }
  }

  .navbar-icons {
    padding: 0 $navbar-padding-horizontal;
    margin-top: 0;
    margin-bottom: 0;

    > li > a {
      //      margin-top:    6px;
      padding-top: 3px;
      padding-bottom: 3px;
      border-left: 1px solid rgba(lighten($brand-classic, 50%), 0.25);
      font-weight: 400;
      line-height: 20px;
      color: $brand-lightblue;
      text-transform: none;
    }

    @media (max-width: $screen-xs) {
      > li > a {
        border-left: 0 transparent;
        line-height: 44px;
      }

      > li + li > a {
        padding-right: 20px;
        padding-left: 5px;
      }
    }

    > .ttd-mobile {
      > a {
        position: relative;
        top: 4px;
        border: 2px dashed $brand-tension;
        border-radius: 12px;
        margin-right: $navbar-padding-horizontal;
        line-height: 1;

        > img {
          margin: 6px 7px 5px;
        }
      }
    }
  }

  @media (min-width: $screen-xs) and (max-width: $screen-xs-max) {
    .navbar-brand {
      position: absolute;
      left: 50%;
      margin-left: -200px;
      margin-top: 0; // 6px;

      &.ffm {
        left: 0;
        margin-left: 0;
      }

    }

    .navbar-icons > li > a {
      padding-top: 5px;
      padding-bottom: 2px;
    }
  }
}

.navbar-toggle {
  $bar-thickness: 2px;
  $bar-transition-duration: 0.2s;
  $navbar-dark-color: $brand-lightblue;

  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: auto;
  height: auto;
  color: $navbar-dark-color;
  border-color: transparent;

  &:active,
  &:focus,
  &:active:focus {
    outline: none;
  }

  &:after {
    content: "Menü";
    position: relative;
    top: 9px;
    left: 0;
    font-family: $font-family-sans-serif;
    font-size: 0.8rem;
    @media (max-width: $screen-xs-max) {
      top: 1px;
      left: 3px;
      font-size: 11px;
    }
  }

  span {
    display: block;
    position: relative;
    top: 6px;
    @media (max-width: $screen-xs-max) {
      top: 0;
    }
    left: 3px;
    height: $bar-thickness;
    width: 26px;
    white-space: nowrap;
    text-indent: -1000rem;
    font-size: $bar-thickness;
    background: $navbar-dark-color;

    &::before,
    &::after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: $bar-thickness;
      background-color: $navbar-dark-color;
      content: "";
    }
    &:before {
      top: -6px;
    }
    &:after {
      bottom: -6px;
    }
  }

  &.hamburger--htx {
    span {
      transition-property: background;
      transition-duration: 0s, $bar-transition-duration;
      transition-delay: 0s, $bar-transition-duration;

      &:before,
      &:after {
        transition-duration: $bar-transition-duration, $bar-transition-duration;
        transition-delay: 0s;
      }
      &:before {
        transition-property: top, transform;
      }
      &:after {
        transition-property: bottom, transform;
      }
    }

    /* active state, i.e. menu open */
    &:not(.collapsed) {
      span {
        background-color: transparent;

        &:before {
          top: 0;
          transform: rotate(45deg);
        }
        &:after {
          bottom: 0;
          transform: rotate(-45deg);
        }
        &:before,
        &:after {
          transition-delay: 0s, $bar-transition-duration;
        }
      }
    }
  }
}

/*
.stuck {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: $zindex-navbar-fixed;

    $shadow: 0 0 6px rgba(0, 0, 0, .67);
    @include box-shadow($shadow);

}
*/
.navbar-stuck--not-top {
  position: fixed;
  right: 0;
  left: 0;
  top: -20px;
  z-index: $zindex-navbar-fixed;

  $shadow: 0 0 6px rgba(0, 0, 0, 0.67);
  @include box-shadow($shadow);
}

.navbar-main {
  .ttd-desktop {
    > a {
      margin-top: 7px;
      border: 2px dashed $brand-tension;
      border-radius: 12px;
      margin-right: 35px;

      > img {
        margin: 7px 10px 8px;
      }
    }
  }

  .ttd-mobile {
    > a {
      margin-top: 12px;
      border: 2px dashed $brand-tension;
      border-radius: 12px;
      margin-right: 20px;
      line-height: 1;

      > img {
        margin: 6px 7px 5px;
      }

      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        margin-right: 14px;

        > img {
          margin: 6px 6px 5px;
        }
      }

    }
  }
}

.ie .ttd-mobile {
  display: none;
}
