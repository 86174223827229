.buydisclaimer {
    position: fixed;
    z-index: 9005;
    top: 100px;
    left: 0;
    width: 100%;
    padding: 15px;
    overflow: hidden;

    @media (min-width: $screen-xs) {
      top: 40%;
    }

    /* @media (min-width: $screen-xs) {
        bottom: 90px;
    }
    @media (min-width: $screen-sm) {
        bottom: 80px;
    }
    @media (min-width: $screen-md) {
        bottom: 70px;
    } */

    .pp-body {
        font-color: #07487e;
        background: #cfe8ef;
        padding: .5em 1em;
        @include box-shadow(4px 4px 8px rgba(0,0,0,0.4));
        overflow: auto;
        position: relative;


        .disclaimer-box {
            margin-right: 40px;
        }

        p {
            margin-bottom: .5em;
            line-height: 1.33;
        }
    }

    &.bottom {
        bottom: 0;
      }
  }
.obligationframe {
    display: none;
    position: fixed;
    z-index: 9002;
    top: 100px;
    left: 0;
    width: 100%;
    height: 150px;
    padding: 15px;
    overflow: hidden;

    @media (min-width: $screen-xs) {
      top: 40%;
    }

    /* @media (min-width: $screen-xs) {
        bottom: 90px;
    }
    @media (min-width: $screen-sm) {
        bottom: 80px;
    }
    @media (min-width: $screen-md) {
        bottom: 70px;
    } */

    .pp-body {
        background: white;
        border: 1px solid $gray-lighter;
        padding: .5em 1em;
        height: 120px;
        @include box-shadow(4px 4px 8px rgba(0,0,0,0.4));
        overflow: auto;
        position: relative;


        .disclaimer-box {
            margin-right: 40px;
        }

        p {
            margin-bottom: .5em;
            line-height: 1.33;
        }
    }

    &.bottom {
        bottom: 0;
      }
}

.ppclose {
    position: absolute;
    right: 3px;
    top: 0;
    font-size: 24px;
    line-height: 1;
    overflow: visible;
}
.bdclose:hover,
.bdclose:active,
.bdclose:focus,
.ppclose:hover,
.ppclose:active,
.ppclose:focus {
    text-decoration: none;
    color: $brand-intensiv;
    @include opacity(1);
}

.gi-logo {
    >  svg {
        width: 130px;
    }

    @media (min-width: $screen-xs) {
        float: right;
        width: 20%;
        > svg {
            width: 100%;
        }
    }
}



/*
.disclaimer {
    margin-top: $grid-gutter-width;
    border-top: 1px solid  $hr-border;
    padding-top: ( $grid-gutter-width / 2 );
    color: #999;
}
*/
/* @media only screen and (max-width: 480px) {

    #pflichtpop {
        position: absolute;
        top: 19%;
        left: 0;
        bottom: auto;
        padding: 2%;
    }

    .nowrap,
    .nobr {
        white-space: normal;
    }
} */
