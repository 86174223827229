// Core mixins
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_mixins";  // bootstrap-sass
@import "./_flexbox";  // bootstrap-sass

@mixin nobr() {
  word-wrap: normal;
  -webkit-hyphens: none;
     -moz-hyphens: none;
      -ms-hyphens: none; // IE10+
       -o-hyphens: none;
          hyphens: none;
}

@import "bower_components/bootstrap-material-design/sass/_mixins";
@import "./_shadows"; // "bootstrap-material-design/sass/_shadows";


@mixin button-option($color, $background, $border) {
    color: $color;
    background-color: $background;
    border-color: $border;


    &:focus,
    &.focus {
        color: lighten($color, 20%);
        background-color: darken($background, 5%);
        border-color: darken($border, 15%);
        @include shadow-z-1();
    }
    &:hover {
        color: lighten($color, 20%);
        background-color: darken($background, 5%);
        border-color: darken($border, 7%);
        @include shadow-z-1();
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($border, 12%);

        &:hover,
        &:focus,
        &.focus {
            color: $color;
            background-color: darken($background, 17%);
            border-color: darken($border, 25%);
            @include shadow-z-1();
        }
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus,
        &.focus {
            background-color: $background;
            border-color: $border;
        }
    }

    .badge {
        color: $background;
        background-color: $color;
    }
}
