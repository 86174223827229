//
// Cards
// --------------------------------------------------


// Base class
.card {
    @include clearfix();
    overflow: hidden;
    background-color: rgba(255, 255, 255, 1);
    margin-bottom: 20px;
    @include shadow-z-1();
    @include transition(all 0.2s ease);

    &.flat {
        box-shadow: none;
    }

    &:hover {
        background-color: rgba(255, 255, 255, 1);
        @include shadow-z-3();
    }

    &.card-product {
        &:hover {
            @include shadow-z-3($brand-primary);
        }
    }

    .card-image {
        text-align: center;

        &.card-gradient {
            @include gradient-vertical($start-color: transparent, $end-color: rgba($brand-classic, .12));
        }

        > img {
            margin: 0 auto;
        }
    }


    .card-content {
        padding: 16px 24px;
        @include clearfix();

        .card-heading {
            margin-top: 0;
            margin-bottom: ($line-height-computed / 4);
        }

        p {
            margin-bottom: 0; //6px; // ($line-height-computed / 4);
        }

        > :last-child {
            margin-top:  6px; // ($line-height-computed / 4);

            .btn-like {
                font-size: 89%;
            }
        }

        > form {
            margin-top: ($line-height-computed / 2);
        }

        &:after {
            content: "";
            display: table;
            clear: both;
        }
    }


    .card-tvc {
        background-image: url('../img/cards/tvc.CACHEBUSTER.jpg');
    //    background-origin: padding-box;
        background-size: cover;
        background-position: center;
    }


    @media (min-width: $screen-sm-min) {
        .col-img {
            position: relative;
            left: (floor($grid-gutter-width/2) * -1);
        }

        .col-img-right {
            padding-right: 0;
            > img {
                float: right;
            }
        }
    }

    @media (max-width: $screen-xs-max) {

        &.last-card {
            margin-bottom: 0;
        }

        .col-img,
        .col-img-right {
            margin: floor($grid-gutter-width/2) 0;
        }
        .col-img-right {
            float: right;
        }
    }

    @media (max-width: ($screen-xs - 1)) {

        .card-content {
            padding: 16px;
        }

        .col-img,
        .col-img-right {
            margin-bottom: 0;
        }
    }

}

// Sizes
.card-lg {
    padding: 24px;
}
.card-sm {
    padding: 9px;
}

.streifen {
    .card-lightblue {
        background-color: $brand-lightblue;
    }
    .card-primary {
        color: #fff;
        background-color: $brand-primary;

        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            color: #fff;
        }
    }
    .card-classic {
        color: #fff;
        background-color: $brand-classic;

        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            color: #fff;
        }
    }
    .card-intensiv {
        color: #fff;
        background-color: $brand-intensiv;

        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6 {
            color: #fff;
        }
    }
}
