.hyphenate {
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.donthyphenate {
    @include nobr();
}
.nobr,
.nowrap,
.neverwrap {
    @include nobr();
    white-space: nowrap;
}

.ellipse,
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.underlined {
    text-decoration: underline;
}

.no-margin {
    margin: 0;
}
.no-border {
    border: 0;

    > tbody > tr > td {
        border: 0;
    }
}

.float-center {
    margin: 0 auto;
}

.relativ {
    position: relative;
}

.svg-max {
    width: 100%;
    height: auto;
    margin: floor(( $line-height-computed / 2 )) 0;
}

.appear {
    @include opacity(0);
}

