//
// Streifen
// --------------------------------------------------


// Base class
.streifen {
    width: 100%;
    height: auto;
    // overflow: hidden;
    // box-shadow: 0 -3px 3px rgba(black, .12);
    border: solid white;
    border-width: 3px 0;


    &.disable-mobile-padding {
        @media (max-width: $screen-sm-min) {
            >div {
                padding: 0;
            }
        }
    }

    &.min-mobile-padding {
        @media (max-width: $screen-sm-min) {
            >div {
                padding: 16px 0;
            }
        }
    }


    &.disable-mobile-bottom {
        @media (max-width: $screen-sm-min) {
            >div {
                padding-bottom: 0;
            }
        }
    }



    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        color: $brand-primary;
    }

    .text-white {
        color: #fff;
    }

    .text-mobile-center {

        @media (max-width: $screen-sm-max) {
            text-align: center;
            display: block;
        }

    }

    .streifen-content,
    .content-wirkweise {
        > :first-child {
            margin-top: 0;
        }

        > :last-child {
            margin-bottom: 0;
        }
    }
}


.streifen-lg {
    > div {
        padding: 78px 0;
    }
    .padding-col {
        padding-top: 78px;
        padding-bottom: 78px;
    }
    > div.container-no-padd {
        padding-top: 0;
        padding-bottom: 0;
    }

    @media (max-width: $screen-xs) {
        .padding-col {
            padding-top: 39px;
            padding-bottom: 0;
        }
    }
}


// Sizes
@media (min-width: $screen-sm-min) {
    .streifen-lg {
        > div {
            padding: 104px 0;
        }
        .padding-col {
            padding-top: 104px;
            padding-bottom: 104px;
        }

        > div.container-no-padd {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
.streifen-sm {
    > div,
    .padding-col {
        padding: 24px 0;
    }
}



//
// Cards
// --------------------------------------------------


// Base class
.card-like {

    h2 {
        margin-bottom: ($line-height-computed / 4);
    }

    p {
        margin-bottom: 0; //6px; // ($line-height-computed / 4);
    }

    > :last-child {
        margin-top: 6px; // ($line-height-computed / 4);

        .btn-like {
            font-size: 89%;
        }
    }
}


.streifen-weiss {
    background-color: #fff;

    .streifen-content {
        background-color: rgba(255,255,255,0.9);

        $shadow: 0 0 10px rgba(255, 255, 255, .9);
        @include box-shadow($shadow);

    }
}


.streifen-lightblue {
    background-color: $brand-lightblue;
}

.streifen-classic {
    background-color: $brand-classic;
}
.streifen-intensiv {
    background-color: $brand-intensiv;
}

.streifen-blau {
    background-color: rgba($brand-primary, 0.8);
}

.streifen-bg-primary {
/*     background-image: url('../img/streifen/blaetter.CACHEBUSTER.jpg');
    background-size: cover;
    background-attachment: fixed; */
    background-color: $brand-primary;

}
.streifen-bg-light {
    background-color: $brand-lightblue;
}

.streifen-bg-thank-you {
        > div {
            padding: 0;
        }

}

.streifen-3fach {
    background-color: #018bc1;

    > .container {
        background-image: url('../img/streifen/3fach.CACHEBUSTER.jpg');
        background-origin: padding-box;
        background-size: cover;
        background-position: top right;
        background-repeat: no-repeat;

        @media (max-width: $screen-sm-max) {
            background-image: url('../img/streifen/3fach-sm.CACHEBUSTER.jpg');
        }
        @media (max-width: $screen-xs-max) {
            background-image: url('../img/streifen/3fach-xs.CACHEBUSTER.jpg');
        }
        @media (max-width: $screen-xs) {
            background-size: 100%;
        }
    }

}

.streifen-tension {
  background-color: #018bc1;

  &.lazyloaded > .container {
      background-image: url('../img/streifen/tension.CACHEBUSTER.jpg');
      background-origin: padding-box;
      background-size: cover;
      background-position: top right;
      background-repeat: no-repeat;

      /* @media (max-width: $screen-sm-max) {
          background-image: url('../img/streifen/tension-sm.CACHEBUSTER.png');
      } */
      @media (max-width: $screen-xs-max) {
          background-image: url('../img/streifen/tension-xs.CACHEBUSTER.jpg');
      }
      @media (max-width: $screen-xs) {
          background-size: 100%;
      }
  }

}


.streifen-portfolio {
    background-image: url('../img/streifen/portfolio.CACHEBUSTER.jpg');
    background-origin: padding-box;
    background-size: 100% 100%;
    background-position: top left;
}

.streifen-bg-apotheke {
    background-color: $brand-lightblue;
    &.lazyloaded > .container {
        background-image: url('../img/streifen/apo-finder.CACHEBUSTER.jpg');
        background-origin: padding-box;
        background-size: cover;
        background-position: top right;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        > div {
            padding: 55px 0;
        }
    }
}

.streifen-bg-tvc.lazyloaded {
    background-image: url('../img/streifen/000017082456-fade.CACHEBUSTER.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (min-width: $screen-md-min) {
        background-size: 100% 100%;
        background-position: left center;
    }
}
.streifen-bg-historie {
    background-color: $brand-lightblue;
    &.lazyloaded > .container {
        background-image: url('../img/streifen/history.CACHEBUSTER.jpg');
        background-size: cover;
        background-position: top right;
        background-repeat: no-repeat;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        > div {
            padding: 55px 0;
        }
    }
}

.streifen-bg-test {
    background-image: url('../img/streifen/test.CACHEBUSTER.jpg');
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;


    @media (min-width: $screen-sm-min) {
        background-position: bottom left;
    }

    @media (max-width: $screen-xs) {
        min-height: 180px;
    }
}

.streifen-bg-kopfschmerzen {
    background-color: $brand-lightblue;
    > .container {
        background-image: url('../img/streifen/kopfschmerzen-2018.CACHEBUSTER.jpg');
        background-size: cover;
        background-position: center right;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        > div {
            padding: 66px 0;
        }
    }
}

.streifen-bg-verspannungen {
  background-color: $brand-lightblue;
  &.lazyloaded > .container {
      background-image: url('../img/streifen/verspannungen.CACHEBUSTER.jpg');
      background-size: cover;
      background-position: center right;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      > div {
          padding: 66px 0;
      }
  }
}

.streifen-bg-migraene {
    background-color: $brand-primary;
    &.lazyloaded > .container {
        background-image: url('../img/streifen/migraine.CACHEBUSTER.jpg');
        background-size: cover;
        background-position: center left;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        > div {
            padding: 66px 0;
        }
    }
}

.streifen-bg-wissenswertes {
  background-color: $brand-primary;
  &.lazyloaded > .container {
      background-image: url('../img/streifen/wissenswertes.CACHEBUSTER.jpg');
      background-size: cover;
      background-position: center left;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      > div {
          padding: 66px 0;
      }
  }
}



.streifen-bg-faq {
    background-image: url('../img/streifen/000082939357long.CACHEBUSTER.jpg');
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;

    @media (max-width: $screen-xs) {
        background-image: none;
        &:after {
            margin-top: -40px;
            content: ' ';
            display: block;
            height: 100px;
            width: 100%;
            background-image: url('../img/streifen/000082939357long.CACHEBUSTER.jpg');
            background-size: contain;
            background-position: center -50%;
            background-repeat: no-repeat;
        }
    }
}

.streifen-bg-glossar {
    background-image: url('../img/streifen/76017566_M.CACHEBUSTER.jpg');
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;

    @media (max-width: $screen-xs) {
        background-image: none;
        &:after {
            margin-top: -40px;
            content: ' ';
            display: block;
            height: 125px;
            width: 100%;
            background-image: url('../img/streifen/76017566_S.CACHEBUSTER.jpg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

}

.streifen-bg-kopfnuss {
    background-color: $brand-lightblue;
    @include gradient-vertical($brand-lightblue, white);
    padding: 104px 0;
    &.lazyloaded > .container {
        background-image: url('../img/streifen/kopfnuss.CACHEBUSTER.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 80% center;
        @media (max-width: $screen-sm) {
            background-position: 100% 0;
        }
        @media (max-width: 480px) {
            background-image: none;
        }
    }

    @media (max-width: 480px) {
        padding: 184px 0 24px;

        background-image: url('../img/streifen/kopfnuss-xs.CACHEBUSTER.png');
        background-repeat: no-repeat;
        background-position: center top;
    }
}

.streifen-bg-tagebuch {
    background-color: $brand-lightblue;
    &.lazyloaded > .container {
        background-image: url('../img/streifen/tagebuch.CACHEBUSTER.jpg'); // 100074059
        background-size: cover;
        background-position: center;
    }
}
.streifen-bg-ecards {
    background-image: url('../img/streifen/96011765.CACHEBUSTER.jpg');
    background-size: cover;
    background-position: bottom center;
}


.streifen-bg-news {
    background-image: url('../img/streifen/news-lg.CACHEBUSTER.jpg');
    background-size: cover;
    background-position: center left;
    background-repeat: no-repeat;

    @media (max-width: $screen-xs) {
        background-image: none;
        &:after {
            content: ' ';
            display: block;
            position: relative;
            top: 9px;
            height: 180px;
            width: 100%;
            background-image: url('../img/streifen/news.CACHEBUSTER.jpg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}

.streifen-bg-fachkreise {
    background-image: url('../img/streifen/83922659.CACHEBUSTER.jpg');
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;
/*
    @media (min-width: $screen-lg) {
        background-position: 50vw center;
    }
 */

    @media (max-width: 600px) {
        .col-card {
            background-color: rgba(255,255,255,0.8);

            $shadow: 0 0 10px rgba(255, 255, 255, .8);
            @include box-shadow($shadow);
        }
    }

    @media (max-width: $screen-xs) {
        background-image: none;
        &:after {
            margin-top: -40px;
            content: ' ';
            display: block;
            height: 200px;
            width: 100%;
            background-image: url('../img/streifen/83922659.CACHEBUSTER.jpg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}

.white-gradient {
    @include gradient-vertical(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.1));
}
.white-gradient-invers {
    @include gradient-vertical(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.7));
}

.classic-gradient {
    @include gradient-vertical(rgba($brand-classic, 0.7), transparent);
    &.full {
        @media (max-width: $screen-sm-max) {
            min-height: 100vh;
        }
    }
}
.classic-gradient-invers {
    @include gradient-vertical(transparent, rgba($brand-classic, 0.7));
        &.full {
            @media (max-width: $screen-sm-max) {
                min-height: 100vh;
            }
        }
}

.primary-gradient {
    @include gradient-vertical(rgba($brand-primary, 0.7), transparent);
}
.primary-gradient-invers {
    @include gradient-vertical(transparent, rgba($brand-primary, 0.7));
}

.intensiv-gradient {
    @include gradient-vertical(rgba($brand-intensiv, 0.7), transparent);
}
.intensiv-gradient-invers {
    @include gradient-vertical(rgba(255,255,255,.2), rgba($brand-intensiv, 0.5));
}

.streifen {
    @media (min-width: 1024px) {
        .container-wirkweise {
            background-size: auto;
        }

        .container-tension {
          padding-top: 180px;
          padding-bottom: 180px;
      }
    }
    @media (max-width: $screen-xs-max) {
        &.streifen-lg {
            .container-lgmx {
                padding-top: 260px;
                padding-bottom: 19px;
            }
        }
    }
    @media (max-width: $screen-xs) {
        &.streifen-lg {
            .container-lgmx {
                padding-top: 180px;
                padding-bottom: 19px;
            }
        }
    }
    @media (max-width: 600px) {
        &.streifen-lg {
            &.streifen-lgx,
            .container-lgx {
                padding-top: 180px;
                padding-bottom: 19px;
            }
        }
    }
}



.streifen-kopfschmerztypen {
  background-color: $brand-primary;
  &.lazyloaded > .container {
      background-image: url('../img/streifen/kopfschmerztypen.CACHEBUSTER.jpg');
      background-size: cover;
      background-position: center right;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      > div {
          padding: 66px 0;
      }
  }

  @media (max-width: $screen-xs-max) {
    &.streifen-lg {
        .container-lgmx {
            padding-top: 260px;
            padding-bottom: 19px;
        }
    }
  }
  @media (max-width: $screen-xs) {
      &.streifen-lg {
          .container-lgmx {
              padding-top: 180px;
              padding-bottom: 9px;
          }
      }
  }
}

.streifen-coffein {
  background-color: $brand-info;
  &.lazyloaded > .container {
      background-image: url('../img/streifen/coffein.CACHEBUSTER.jpg');
      background-size: cover;
      background-position: top right;
      background-repeat: no-repeat;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      > div {
          padding: 55px 0;
      }
  }
}


.page-footer {

    .footer {
        margin-bottom: ($line-height-computed / 2);
    }

//    background-color: $brand-lightblue;
    margin-top: 2em;
    padding-bottom: 2em;


    .disclaimer-box {
        color: #7fa1bb;

        .small,
        small,
        p {
            line-height: 1.22;
        }
    }

    a {
        color: $brand-primary;
    }
}
