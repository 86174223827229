// @import "bootstrap/_forms";


.form-group .form-control,
.form-control {
    box-shadow: none;

//    @include form-control-focus;
     &:focus {
        // border-color: $brand-lightblue;
    }
    &.border-light {
        // border-color: $brand-lightblue;
    }
}

.form-group {

    .control-label {
        font-family: $font-family-condensed;
        text-transform: uppercase;
    }
}

.form-flat {
    .form-group {
        .form-control {

            // border-color: transparent;
        }
    }
}


.form-white {
    @extend .row;
//    background-color: rgba(255,255,255,.67);

    > .form-group {
        margin-right: 0;
        margin-left: 0;
    }
}

/*
.form-group.required {
    .form-control {
//      background-size: 0 1px, 100% 1px;
      background-image: linear-gradient($brand-primary, $brand-primary), linear-gradient($brand-classic, $brand-classic);
    }

    .control-label {
        color: $brand-classic;
    }
}
*/
.form-group.form-group-lg.required label.control-label,
.form-group.required label.control-label
 {
    color: $brand-classic;
}


.form-apofind {
    .form-control {
        box-shadow: none;

         &:focus {
            border-color: $brand-lightblue;
        }
        &.border-light {
            // border-color: $brand-lightblue;
        }
    }
}

.form-group,
.input-group {
    input:-webkit-autofill {
        background-color: #fff !important;
        -webkit-box-shadow: 0 0 0px 1000px white inset;
        box-shadow: 0 0 0px 1000px white inset;
        -webkit-text-fill-color: $brand-primary !important;
    }
}


