/* "bootstrap-material-design/sass/_shadows"; */

@mixin shadow-z-1($shadow-color: $gray-base){
    box-shadow:
      0 1px 6px 0 rgba($shadow-color, 0.12),
      0 1px  6px 0 rgba($shadow-color, 0.12);
  }

  @mixin shadow-z-1-hover($shadow-color: $gray-base){
    box-shadow:
      0 5px 11px 0 rgba($shadow-color, 0.18),
      0 4px 15px 0 rgba($shadow-color, 0.15);
  }

  @mixin shadow-z-2($shadow-color: $gray-base){
    box-shadow:
      0 8px 17px 0 rgba($shadow-color, 0.2),
      0 6px 20px 0 rgba($shadow-color, 0.19);
  }

  @mixin shadow-z-3($shadow-color: $gray-base){
    box-shadow:
      0 12px 15px 0 rgba($shadow-color, 0.24),
      0 17px 50px 0 rgba($shadow-color, 0.19);
  }

  @mixin shadow-z-4($shadow-color: $gray-base){
    box-shadow:
      0 16px 28px 0 rgba($shadow-color, 0.22),
      0 25px 55px 0 rgba($shadow-color, 0.21);
  }

  @mixin shadow-z-5($shadow-color: $gray-base){
    box-shadow:
      0 27px 24px 0 rgba($shadow-color, 0.2),
      0 40px 77px 0 rgba($shadow-color, 0.22);
  }


  /* Shadows (from mdl http://www.getmdl.io/) */

  // Focus shadow mixin.
  @mixin focus-shadow($shadow-color: $gray-base){
    box-shadow: 0 0 8px rgba($shadow-color,.18),
    0 8px 16px rgba($shadow-color,.36);
  }
