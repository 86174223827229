@import "bootstrap/_navs";


.nav-pills {
    > li {
        > a {
            background-color: $brand-lightblue;
        }
    }
}


$responsive-tab-breakpoint: 600px;
.nav-tabs-product.nav-tabs {
  position: relative;
  z-index: 10;
  overflow: visible;
  border-bottom: none;

  > li {
    display: none;
    float: none;
    text-align: center;

    &:last-of-type > a {
      margin-right: 0;
    }

    > a {
        border-radius: 0;
        background-color: $brand-lightblue;
        border: 1px solid #fff;

        margin-right: 4px;

        color: #fff;
        background-color: transparent;
        border-color: transparent;

        &:hover,
        &:focus,
        &:active {
            color: $brand-classic;
            background-color: $brand-lightblue;
        }
        &:hover {
            box-shadow: inset 0 -4px 8px -4px rgba($brand-primary,.1);
        }

    }

    &.active {
      display: block;
      a {
        background-color: #fff;
        color: $brand-primary;
        font-weight: 700;


        border-bottom-color: transparent;
      }
    }

    @media(min-width: $responsive-tab-breakpoint) {
      display: block;
      float: left;
    }

  }

  &.open {

    i.fa {
      &.fa-chevron-up {
        display: block;
        @media(min-width: $responsive-tab-breakpoint) {
          display: none;
        }
      }
      &.fa-chevron-down {
        display: none;
      }
    }

    > li {
      display: block;

      a {
        border-radius: 0;
      }
    }
  }
}


/**
*
*
* http://stackoverflow.com/questions/25855428/make-bootstrap-3-tabs-responsive
*
*
**/

/*
$responsive-tab-breakpoint: 600px;

.nav-tabs-responsive.nav-tabs {
  position: relative;
  z-index: 10;
//  height: 42px;
  overflow: visible;
  border-bottom: none;

  i.fa {
    position: absolute;
    top: 14px;
    right: 22px;
    &.fa-chevron-up {
      display: none;
    }
    @media(min-width: $responsive-tab-breakpoint) {
      display: none;
    }
  }

  > li {
    display: none;
    float: none;
    text-align: center;

    &:last-of-type > a {
      margin-right: 0;
    }

    > a {
        margin-right: 0;
        border-radius: 0;
        background-color: $brand-lightblue;
        border: 1px solid #fff;

        @media(min-width: $responsive-tab-breakpoint) {
            margin-right: 4px;

            color: #fff;
            background-color: transparent;
            border-color: transparent;

            &:hover,
            &:focus,
            &:active {
                color: $brand-classic;
                background-color: $brand-lightblue;
            }
        }
    }

    &.active {
      display: block;
      a {
        background-color: #fff;
        color: $brand-primary;
        font-weight: 700;
        @media(max-width: ($responsive-tab-breakpoint - 1)) {
          background-color: $brand-lightblue;
          border-color: #fff;
        }

        @media(min-width: $responsive-tab-breakpoint) {
          border-bottom-color: transparent;
        }
      }
    }

    @media(min-width: $responsive-tab-breakpoint) {
      display: block;
      float: left;
    }

  }

  &.open {

    i.fa {
      &.fa-chevron-up {
        display: block;
        @media(min-width: $responsive-tab-breakpoint) {
          display: none;
        }
      }
      &.fa-chevron-down {
        display: none;
      }
    }

    > li {
      display: block;

      a {
        border-radius: 0;
      }
    }
  }
}
*/
