
@include responsive-invisibility('.visible-xxs');
@media (max-width: (($screen-xs))) {

    @include responsive-visibility('.visible-xxs');

    @include responsive-invisibility('.hidden-xxs');

    @include make-grid(xxs);

    .col-xxs-12 {
        width: 100%;
        left: 0;
    }
}

@include responsive-invisibility('.visible-xs600');
@media (max-width: 600px) {

    @include responsive-visibility('.visible-xs600');

    @include responsive-invisibility('.hidden-xs600');

    @include make-grid(xs600);

    .col-xs600-12 {
        width: 100%;
        left: 0;
    }
}

@media (max-width: $screen-xs-max) {
    .img-xs-75 {
        max-width: 75%;
    }
    .img-xs-67 {
        max-width: 67%;
    }
    .img-xs-50 {
        max-width: 50%;
    }
    .img-xs-33 {
        max-width: 33%;
    }

    .img-xs-right {
        float: right;
    }
}


.col-25 {
    width: 25%;
    padding-left: ceil(($grid-gutter-width / 2));
    padding-right: floor(($grid-gutter-width / 2));
}

.nav-pills {
    > .col-50 {
        width: 50%;
        padding-left: ceil(($grid-gutter-width / 2));
        padding-right: floor(($grid-gutter-width / 2));
        margin-left: 0;
        text-align: center;
    }
}

.img-responsive-height {
  max-height: 100%;
  width: auto;
}
