.footer {
  float: right;
  padding-bottom: 1em;

  .nav-pills {
    > li {
      border-right: 1px solid $gray-lighter;

      > a {
//                font-weight: 400;
        font-size: 15px;
        padding: 1px $navbar-padding-horizontal;
        background-color: transparent;

        &:hover {
          background-color: transparent;
        }
      }

      &:last-child {
        border-right: 0 solid transparent;
      }
    }

    @media (max-width: $screen-xs-max) {
      > li {
        width: 100%;
        float: none;
        text-align: center;
        border-right: 0 solid transparent;
        margin-top: 2px;
        margin-bottom: 6px;

        + li {
          margin-top: 6px;
          margin-left: 0; // no need for this gap between nav items
          border-top: 1px solid #f5f5f5;
          padding-top: 4px;

        }

        > a {
          display: block;
          width: 100%;
        }
      }

    }
  }

  @media (max-width: $screen-xs-max) {
    width: 100%;
    float: none;
  }
}