
@import "iefix";

html, body {
    min-height: 101%;
}
body {
    position: relative;
}

@import "./fonts";
@import "node_modules/font-awesome/scss/font-awesome";

// Variables and mixins
@import "_variables";
@import "_mixins";

@import "./_bootstrap-css";

@import "./_mdb";
@import "./_bootstrap-components";
@import "./_bootstrap-utilities";
@import "./_bootstrap-addons";

// @import "./slick";
@import "./_mcustom-scrollbar";
@import "node_modules/animate.css/animate";


@import "./_helper";

@import "../../../../modules/system/assets/css/framework.extras";

@import "./_custom";
