@import "bootstrap/_type";


body {
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    @media (max-width: $screen-xs-max) {
       @include hyphens(auto);
    }
/*     line-height: $headings-line-height; */

    > small,
    > .small {
        color: inherit;
    }

    > strong,
    > .strong {
        font-weight: 700;
//        color: $brand-intensiv;
    }

    &.top-most {
        margin-top: 0;
    }
}

h4, h5, h6,
.h4, .h5, .h6 {
    font-weight: 700;
}

h4, .h4 {
  margin-top: 24px;
  margin-bottom: ($line-height-computed / 2);
}

@media (max-width: $screen-xs-max) {
    &.top-most-xs {
        margin-top: 0;
    }
}

small, .small {
    font-size: 72%;

    /* sup:not(.reg) {
      font-size: 12px;
    } */
}

sup {
  opacity: 0.67;

  &.reg {
    display: inline-block;
    transform: scale(1.27) translateY(0.25em);
    opacity: 1;
  }
}
.ie {
  sup {

    &.reg {
      display: inline-block;
      transform: scale(1.27) translateY(0.1em);
      opacity: 1;
    }
  }
}


dd {
    margin-bottom: 1em;
}

@media (max-width: $screen-md-max) {
    body {

        h1, .h1  { font-size: 32px; }
        h2, .h2  { font-size: 26px; }
        h3, .h3  { font-size: 22px; }
        h4, .h4  { font-size: 18px; }
        h5, .h5  { font-size: 16px; }
        h6, .h6  { font-size: 13px; }

    }
}


@media (max-width: 639px) {
    body {
        font-size: 20px;

        small, .small {
            font-size: 14px;
        }

        h1, .h1  { font-size: 26px; }
        h2, .h2  { font-size: 22px; }
        h3, .h3  { font-size: 20px; }
        h4, .h4  { font-size: 18px; }
        h5, .h5  { font-size: 16px; }
        h6, .h6  { font-size: 14px; }

    }

    p, li, dt, dd, td, th {
       @include hyphens(auto);
    }
}

.text-intensiv {
    color: $brand-intensiv;
}
.text-classic {
    color: $brand-classic;
}
.text-tension {
  color: $brand-tension;
  // text-shadow: 0 1px 6px white, 0 -1px 6px white;
}

.intro {
    a {
        color: $brand-primary;
        text-decoration: underline;

        sup {
            text-decoration: none;
        }

        &.btn,
        &.btn-like {
            text-decoration: none;
        }

    }
}
