@import "bootstrap/_panels";

.panel {
    @include transition(all 0.2s ease);
}

.panel-group {
    .panel-primary {
//        @include box-shadow(none);
        border-radius: 0;
        clear: both;
        overflow: hidden;
        margin-bottom: .5rem;
        border-color: $brand-lightblue;


        .panel-heading {
            cursor: pointer;
            position: relative;
            height: auto;
            padding-left: 60px;
            border-radius: 0;
            background-color: $brand-lightblue;
            @include transition(all 0.33s ease);

            .panel-title {
                overflow: hidden;
                font-weight: 400;

                @media (max-width: $screen-md-max) {
                    padding-top: 3px;
                    padding-bottom: 2px;
                }
            }

            &:after {
                position: absolute;
                top: 0;
                left: 0;
                content: "\f0fe";
                font-family: FontAwesome;
                display: block;
                height: 100%;
                padding: ($padding-large-vertical + 1) $padding-large-horizontal $padding-large-vertical;
            }

            &.active {
                &:after {
                    content: "\f146";
                }
            }
        }

        &.open {
            .panel-collapse {
                position: relative;
                height: auto;

                > .panel-body {
                    overflow: hidden;
                    @media (min-width: $screen-xs-min) {
                      padding-left: 60px;
                    }
                }
            }
        }

        + .panel-primary {
            margin-top: -1px;
        }
    }
}


.panel-group {
    .panel-default {
        @include box-shadow(none);
        border-radius: 0;
        clear: both;
        overflow: hidden;

        .panel-heading {
            cursor: pointer;
            position: relative;
            border-radius: 0;
            @include transition(all 0.33s ease);

            .panel-title {
                overflow: hidden;

                a {
                    &:after {
                        position: absolute;
                        top: 0;
                        right: 0;
                        content: "\f0fe";
                        color: $brand-primary;
                        font-family: FontAwesome;
                        display: block;
                        height: 100%;
                        padding: ($padding-large-vertical + 1) $padding-large-horizontal $padding-large-vertical;
                    }
                }
            }

            &.active {
                .panel-title {
                    a {
                        &:after {
                            content: "\f146";
                            color: $brand-primary;
                            font-family: FontAwesome;
                        }
                    }
                }
            }
        }

        &.open {
            .panel-collapse {
                position: relative;

                > .panel-body {
                    overflow: hidden;
                }

/*                 &:after {
                    content: "\f054";
                    font-family: FontAwesome;
                    height: 100%;
                    padding: ($padding-large-vertical + 1) $padding-large-horizontal $padding-large-vertical;
                } */
            }
        }

        + .panel-primary {
            margin-top: -1px;
        }
    }
}



.panel {
    &.hovered {
        @include shadow-z-2($brand-primary);
    }
}
