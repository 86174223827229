@import "bootstrap/_wells";


// Base class
.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: $well-bg;
    border: 1px solid $well-border;
    border-radius: 0; // $border-radius-base;
    @include box-shadow(none); // inset 0 1px 2px rgba($brand-classic,.15)

    p:last-child {
        margin-bottom: 0;
    }

    blockquote {
        border-color: #ddd;
        border-color: rgba(0,0,0,.15);
    }
}
